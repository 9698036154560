import axios, { AxiosInstance } from "axios";
import { createOptions } from "./CreateOptions";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";
import { CommonWalletReport } from "../interfaces/common-trading/CommonTradingReport";

const http = axios.create({
  baseURL: process.env.REACT_APP_LSTMFORGE_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class LSTMForgeServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getModelManifests = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<ModelManifestsOverview[]> | null>(
      `/model-manifests`,
      options
    );
  };

  public loadModelManifest = async (token: string, manifestId: string) => {
    const options = createOptions(token);
    const body = {
      manifest_id: manifestId,
    };
    return await http.post<CustomResponse<ModelManifest> | null>(
      `/load-model-manifest`,
      body,
      options
    );
  };

  public getModelBacktestReport = async (token: string, reportId: string) => {
    const options = createOptions(token);
    const body = {
      blob_name: reportId,
    };
    return await http.post<CommonWalletReport | null>(
      `/load-model-report`,
      body,
      options
    );
  };
}

export const LSTMForgeService = new LSTMForgeServiceClass(http);

export interface ModelManifestsOverview {
  series_name: string;
  id: string;
}

export interface ModelManifest {
  series_name: string;
  id: string;
  currencies: string[];
  reports?: ModelBenchmarkReports;
}

export interface ModelBenchmarkReports {
  [currencyName: string]: ModelBenchmarkReportEntry;
}

export interface ModelBenchmarkReportEntry {
  [reportId: string]: number;
}
