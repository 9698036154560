import "./profile-editor.scss";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  UserInfoUpdateForm,
  UsermanagerService,
} from "../../../services/UserManagerService";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppInsightsContext,
  RefetchUserInfoContext,
  ToastContext,
  UserInfoContext,
} from "../../../App";
import { Avatar, Space, TextInput } from "@mantine/core";
import { CommonButton } from "../../../components/buttons/neoton-common-button/CommonButton";
import { useQuery } from "react-query";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { UserProfileComponent } from "../../../components/community/user-profile-component/UserProfileComponent";
import { FaUserEdit } from "react-icons/fa";
import { FaImagePortrait } from "react-icons/fa6";
import { motion } from "framer-motion";
import { getStarterProfilePicUrl } from "../../../utils/cdnUtil";
import { FiUploadCloud } from "react-icons/fi";

interface Props {
  activeTheme: string;
  existingUsername: string | null | undefined;
  setModalOpened: (value: boolean) => void;
}
export function ProfileEditor(props: React.PropsWithChildren<Props>) {
  const appInsights = useContext(AppInsightsContext);
  const triggerToast = useContext(ToastContext);
  const { getAccessTokenSilently } = useAuth0();
  const userInfo = useContext(UserInfoContext);
  const refetchUserInfo = useContext(RefetchUserInfoContext);

  const [userInfoForm, setUserInfoForm] = useState<UserInfoUpdateForm>({
    username: userInfo?.username ?? "",
    blob_id: undefined,
  }); // This is used to force a re-render of the image component when the image changes
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  const [tempUrl, setTempUrl] = useState<string | undefined>(undefined); // This is used to force a re-render of the image component when the image changes

  const invalidInput = useMemo(() => {
    const disallowedChars = [
      " ",
      "_",
      "-",
      ".",
      "|",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "+",
      "=",
      "~",
      "`",
      "[",
      "]",
      "{",
      "}",
      ";",
      ":",
      "'",
      '"',
      "<",
      ">",
      ",",
      "?",
      "/",
      "\\",
    ];
    const blacklistedNames = [
      "ceo",
      "admin",
      "moderator",
      "mod",
      "administrator",
      "owner",
      "founder",
      "developer",
      "dev",
      "staff",
      "helper",
      "cto",
      "coo",
      "cfo",
      "cmo",
    ];

    if (userInfoForm.username.length < 2 || userInfoForm.username.length > 18) {
      return true;
    }

    for (const char of disallowedChars) {
      if (userInfoForm.username.includes(char)) {
        return true;
      }
    }
    // check if username is blacklisted or even contains substrings of blacklisted names
    const username = userInfoForm.username.toLowerCase();
    for (const name of blacklistedNames) {
      if (username.includes(name)) {
        return true;
      }
    }
    return false;
  }, [userInfoForm.username]);

  const fetchStarterProfilePics = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.getStarterProfilePictures(
        token
      );
      return response.data;
    } catch (error: any) {
      appInsights?.trackException({ exception: error });

      triggerToast(error.response.data.error, "error", null);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, appInsights, triggerToast, setLoading]);

  const starterProfilePicsQuery = useQuery(
    "starterProfilePics",
    fetchStarterProfilePics,
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 60000,
    }
  );

  const handleUpdateUser = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await UsermanagerService.updateUser(token, userInfoForm);
      if(response.data.error !== null) {
        triggerToast(response.data.error ?? "Something went wrong", "error", null);
        return
      }
      triggerToast(response.data.message, "success", null);
      refetchUserInfo();
      props.setModalOpened(false);
    } catch (error: any) {
      appInsights?.trackException({ exception: error });
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setLoading(false);
    }
  }, [
    getAccessTokenSilently,
    appInsights,
    userInfoForm,
    triggerToast,
    props,
    refetchUserInfo,
  ]);

  const onImageDrop = useCallback(
    async (files: File[]) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      try {
        setLoadingAvatar(true);
        const response = await UsermanagerService.uploadProfilePicture(
          token,
          files[0]
        );
        if (response.data.error === null) {
          setTempUrl(response.data.payload);
          setUserInfoForm({ ...userInfoForm, blob_id: response.data.payload });
        }
      } finally {
        setLoadingAvatar(false);
      }
    },
    [
      getAccessTokenSilently,
      setTempUrl,
      setLoadingAvatar,
      setUserInfoForm,
      userInfoForm,
    ]
  );

  const handleAvatarClick = useCallback(
    async (url: string) => {
      setLoadingAvatar(true);
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "avatar.png", { type: blob.type });
      await onImageDrop([file]);
    },
    [onImageDrop, setLoadingAvatar]
  );

  return (
    <div className="profile-editor-container">
      <label className="dimmed-label">
        {loadingAvatar ? "Loading avatar..." : "Make yourself known..."}
      </label>

      {userInfo?.user_hash && (
        <UserProfileComponent
          activeTheme={props.activeTheme}
          userHash={userInfo.user_hash}
          username={userInfoForm.username}
          userTitle={userInfo.title ?? undefined}
          tempUrl={tempUrl}
          size={200}
        />
      )}

      {starterProfilePicsQuery.data?.payload && (
        <>
          <label>Pick a profile picture</label>
          <CommonButton
            leftIcon={<FaImagePortrait />}
            onClick={starterProfilePicsQuery.refetch}
            activeTheme={props.activeTheme}
            label={"Randomize"}
            loading={loading}
            compact
          />
          <div className="starter-profile-pics-container">
            {starterProfilePicsQuery.data?.payload?.map((pic, idx) => {
              const url = getStarterProfilePicUrl(pic);
              return (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.2, delay: idx * 0.1 }}
                  key={idx}
                  className="starter-profile-pic"
                >
                  <Avatar
                    src={url}
                    onClick={() => handleAvatarClick(url)}
                    size={80}
                    radius="xl"
                  />
                </motion.div>
              );
            })}
          </div>
        </>
      )}

      <label>Or upload your own</label>
      <Dropzone
        onDrop={onImageDrop}
        maxSize={3 * 1024 ** 2}
        accept={IMAGE_MIME_TYPE}
        multiple={false}
        className="dropzone"
        classNames={{
          root: "dropzone-root",
          active: "dropzone-active",
          reject: "dropzone-reject",
        }}
      >
        {(status) => (
          <div className="dropzone-container">
            <FiUploadCloud />
            <label>Upload image</label>
          </div>
        )}
      </Dropzone>
      <Space h={"xs"} />
      <label>Display name</label>
      <label className="dimmed-label">between 2 and 18 characters</label>
      <TextInput
        value={userInfoForm.username}
        maxLength={18}
        onChange={(event) =>
          setUserInfoForm({
            ...userInfoForm,
            username: event.currentTarget.value,
          })
        }
      />

      <CommonButton
        leftIcon={<FaUserEdit />}
        onClick={handleUpdateUser}
        activeTheme={props.activeTheme}
        disabled={invalidInput}
        label={"Update"}
        loading={loading}
      />
    </div>
  );
}
