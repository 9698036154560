import { motion } from "framer-motion";
import {
  EntryCriteria,
  ExitCriteria,
} from "../../../interfaces/strategyInterfaces/Strategy";
import "./criteria-overview.scss";
import { useContext, useState } from "react";
import { CandleSizeCriterias } from "./CandleSizeCriterias";
import { CriteriaCandleSizeHeader } from "./CriteriaCandleSizeHeader";
import { IndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";
import { IndicatorInfosContext } from "../../../shared-service-contexts/IndicatorsInfoContext";
interface Props {
  activeTheme: string;
  criteriaType: "entry" | "exit";
  criteria?: EntryCriteria | ExitCriteria;
  handleNewCriteriaOverview: (candleSize: string, criteriaType: string) => void;
  handleDeleteCriteria: (
    candleSize: string,
    criteriaType: string,
    index: number
  ) => void;
  readonly?: boolean;
}
export function CriteriaOverview(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(IndicatorsContext);
  const indicatorInfo = useContext(IndicatorInfosContext);

  const [candleSize1DExpanded, setCandleSize1DExpanded] = useState<boolean>(
    (props.criteria?.candle_size_1D.length ?? 0) > 0
  );
  const [candleSize1hExpanded, setCandleSize1hExpanded] = useState<boolean>(
    (props.criteria?.candle_size_1h.length ?? 0) > 0
  );
  const [candleSize5mExpanded, setCandleSize5mExpanded] = useState<boolean>(
    (props.criteria?.candle_size_5m.length ?? 0) > 0
  );

  const critieriaTypeExplainers = {
    entry: "Criterias that must be met to enter a position",
    exit: "Criterias that must be met to exit a position",
  };

  return (
    <motion.div
      className="criteria-overview-container"
      id={
        props.criteriaType === "entry"
          ? "entry-criteria-overview-container"
          : "exit-criteria-overview-container"
      }
      initial={{ opacity: 0, scaleY: 0 }}
      animate={{ opacity: 1, scaleY: 1 }}
      transition={{
        duration: 0.3,
        delay: props.criteriaType === "entry" ? 0.5 : 0.7,
      }}
      exit={{ opacity: 0 }}
    >
      <div className="criteria-type-header-container">
        <label className="criteria-type-label">{props.criteriaType}:</label>
        <label className="dimmed-label">
          {critieriaTypeExplainers[props.criteriaType]}
        </label>
      </div>
      {applicationIndicators && (
        <>
          <CriteriaCandleSizeHeader
            criteriaType={props.criteriaType}
            activeTheme={props.activeTheme}
            readonly={props.readonly}
            addNewCriteria={() => {
              props.handleNewCriteriaOverview("1D", props.criteriaType);
            }}
            criterias={props.criteria?.candle_size_1D ?? []}
            applicationIndicators={applicationIndicators}
            candleSize="1D"
            expanded={candleSize1DExpanded}
            onClick={() =>
              (props.criteria?.candle_size_1D.length ?? 0) > 0 &&
              setCandleSize1DExpanded(!candleSize1DExpanded)
            }
            label={`${props.criteria?.candle_size_1D.length} criterias`}
            indicatorsInfo={indicatorInfo}
          />

          {candleSize1DExpanded && (
            <div className="criteria-candle-size-row">
              <div
                id={
                  props.criteriaType === "entry"
                    ? "entry-connector-criteria-menu-container"
                    : "exit-connector-criteria-menu-container"
                }
                className="connector-line"
              />
              <CandleSizeCriterias
                readonly={props.readonly}
                activeTheme={props.activeTheme}
                handleDeleteCriteria={props.handleDeleteCriteria}
                candleSize="1D"
                criteriaType={props.criteriaType}
                criterias={props.criteria?.candle_size_1D ?? []}
                indicators={applicationIndicators.indicators}
                indicatorsInfo={indicatorInfo}
              />
            </div>
          )}
          <CriteriaCandleSizeHeader
            criteriaType={props.criteriaType}
            activeTheme={props.activeTheme}
            readonly={props.readonly}
            addNewCriteria={() => {
              props.handleNewCriteriaOverview("1h", props.criteriaType);
            }}
            criterias={props.criteria?.candle_size_1h ?? []}
            applicationIndicators={applicationIndicators}
            candleSize="1h"
            expanded={candleSize1hExpanded}
            onClick={() =>
              (props.criteria?.candle_size_1h.length ?? 0) > 0 &&
              setCandleSize1hExpanded(!candleSize1hExpanded)
            }
            label={`${props.criteria?.candle_size_1h.length} criterias`}
            indicatorsInfo={indicatorInfo}
          />
          {candleSize1hExpanded && (
            <div className="criteria-candle-size-row">
              <div
                id={
                  props.criteriaType === "entry"
                    ? "entry-connector-criteria-menu-container"
                    : "exit-connector-criteria-menu-container"
                }
                className="connector-line"
              />

              <CandleSizeCriterias
                readonly={props.readonly}
                activeTheme={props.activeTheme}
                handleDeleteCriteria={props.handleDeleteCriteria}
                candleSize="1h"
                criteriaType={props.criteriaType}
                criterias={props.criteria?.candle_size_1h ?? []}
                indicators={applicationIndicators.indicators}
                indicatorsInfo={indicatorInfo}
              />
            </div>
          )}
          <CriteriaCandleSizeHeader
            criteriaType={props.criteriaType}
            activeTheme={props.activeTheme}
            readonly={props.readonly}
            addNewCriteria={() => {
              props.handleNewCriteriaOverview("5m", props.criteriaType);
            }}
            criterias={props.criteria?.candle_size_5m ?? []}
            applicationIndicators={applicationIndicators}
            candleSize="5m"
            expanded={candleSize5mExpanded}
            onClick={() =>
              (props.criteria?.candle_size_5m.length ?? 0) > 0 &&
              setCandleSize5mExpanded(!candleSize5mExpanded)
            }
            label={`${props.criteria?.candle_size_5m.length} criterias`}
            indicatorsInfo={indicatorInfo}
          />
          {candleSize5mExpanded && (
            <div className="criteria-candle-size-row">
              <div
                id={
                  props.criteriaType === "entry"
                    ? "entry-connector-criteria-menu-container"
                    : "exit-connector-criteria-menu-container"
                }
                className="connector-line"
              />

              <CandleSizeCriterias
                readonly={props.readonly}
                activeTheme={props.activeTheme}
                handleDeleteCriteria={props.handleDeleteCriteria}
                candleSize="5m"
                criteriaType={props.criteriaType}
                criterias={props.criteria?.candle_size_5m ?? []}
                indicators={applicationIndicators.indicators}
                indicatorsInfo={indicatorInfo}
              />
            </div>
          )}
        </>
      )}
    </motion.div>
  );
}
