import "./case-selection.scss";
import {
  Case,
  StrategyVersion,
} from "../../interfaces/strategyInterfaces/Strategy";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  SelectedCaseContext,
  SetSelectedCaseContext,
  AddCaseContext,
  UpdateSelectedCaseContext,
  CaseNamesContext,
  RenameCaseContext,
  CopyCaseContext,
  DeleteCaseContext,
} from "../../pages/strategy-page/StrategyPage";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { TbCodePlus } from "react-icons/tb";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { generateRandomName } from "../../utils/RandomNameGenerator";
import { motion } from "framer-motion";
import { FiCheck, FiX } from "react-icons/fi";
import { CommonIconButton } from "../buttons/neoton-common-button/CommonIconButton";
import { CurrencyLogoArray } from "../common/common-currencies/CurrencyLogoArray";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { Menu } from "@mantine/core";
import { RiMoreLine } from "react-icons/ri";
import { FaCopy, FaTrash } from "react-icons/fa";
import { PiCodeBlock } from "react-icons/pi";
import { CandleSize } from "./StrategyHelperModal";
import { IoCodeWorking } from "react-icons/io5";
import {
  getCriteriaDependencies,
  getTranslatedCriteriaDependencies,
} from "../../utils/ParseCriteriaUtil";
import { IndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { TierBadges } from "./case-control/TierBadges";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  readonly?: boolean;
  children?: React.ReactNode;
  autoFocusNewCase?: boolean;
  openCase?: () => void;
  addCase?: (caseName: string) => void;
  selectCase?: (_selectedCase: Case | undefined) => void;

  entryCriteria?: string;
  exitCriteria?: string;
  candleSize?: CandleSize;
}

export function CaseSelection(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(IndicatorsContext);
  const addCase = useContext(AddCaseContext);
  const selectedCase = useContext(SelectedCaseContext);
  const setSelectedCase = useContext(SetSelectedCaseContext);
  const updateSelectedCase = useContext(UpdateSelectedCaseContext);
  const caseNames = useContext(CaseNamesContext);
  const renameCase = useContext(RenameCaseContext);
  const copyCase = useContext(CopyCaseContext);
  const currencies = useContext(CurrencyContext);
  const deleteCase = useContext(DeleteCaseContext);

  const [newCaseName, setNewCaseName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const validCaseName = useCallback(
    (caseName: string) => {
      const min = 2;
      const max = 32;
      return (
        caseName.length > min &&
        caseName.length <= max &&
        !caseNames.includes(caseName)
      );
    },
    [caseNames]
  );

  const getRandomName = useCallback(async () => {
    setLoading(true);
    setTimeout(async function () {
      const name = generateRandomName();
      setNewCaseName(name);
      setLoading(false);
    }, 1000);
  }, [setNewCaseName, setLoading]);

  const [renamingCase, setRenamingCase] = useState<Case | undefined>();
  const [renameCaseValue, setRenameCaseValue] = useState<string>("");

  const caseCount = props.strategy.body.cases.length;

  const [caseIndexInsertionMap, setCaseIndexInsertionMap] =
    useState<CriteriaInsertionMap>({});

  const collectCriterias = useCallback((strategyCase: Case) => {
    return [
      ...strategyCase?.entry_criteria?.candle_size_1D ?? [],
      ...strategyCase?.entry_criteria?.candle_size_1h ?? [],
      ...strategyCase?.entry_criteria?.candle_size_5m ?? [],
      ...strategyCase?.exit_criteria?.candle_size_1D ?? [],
      ...strategyCase?.exit_criteria?.candle_size_1h ?? [],
      ...strategyCase?.exit_criteria?.candle_size_5m ?? [],
    ];
  }, []);

  return (
    <div className="case-selection-container">
      {!props.readonly && (
        <div className="add-case-container">
          <label className="dimmed-label">Add new case</label>
          <input
            className="case-name-input"
            placeholder="Enter case name"
            autoFocus={props.autoFocusNewCase}
            value={newCaseName}
            onChange={(e) => setNewCaseName(e.target.value)}
          />
          <CommonButton
            style={{ marginLeft: "auto" }}
            loading={loading}
            disabled={!validCaseName(newCaseName)}
            activeTheme={props.activeTheme}
            borderTheme="green-accent"
            compact
            label="Add"
            leftIcon={<TbCodePlus />}
            onClick={() => {
              props.addCase ? props.addCase(newCaseName) : addCase(newCaseName);
              setNewCaseName("");
            }}
          />
          <CommonButton
            style={{ marginLeft: "5px" }}
            loading={loading}
            activeTheme={props.activeTheme}
            compact
            borderTheme="purple-accent"
            label="Generate name"
            leftIcon={<GiPerspectiveDiceSixFacesRandom />}
            onClick={getRandomName}
          />
        </div>
      )}
      {caseCount > 0 && (
        <>
          <label className="dimmed-label">Cases: {caseCount}</label>
          <div className="existing-cases-container">
            {props.strategy.body.cases.map((strategyCase, idx) => {
              const collectedCriterias = collectCriterias(strategyCase);
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className={"case-item"}
                  key={strategyCase.name}
                >
                  {renamingCase === strategyCase ? (
                    <input
                      className={"case-name-input"}
                      placeholder="Case name"
                      value={renameCaseValue}
                      onChange={(e) => setRenameCaseValue(e.target.value)}
                    />
                  ) : (
                    <label
                      onClick={() => {
                        if (props.readonly) return;
                        setRenamingCase(strategyCase);
                        setRenameCaseValue(strategyCase.name);
                      }}
                      className={"case-name-input read-only"}
                    >
                      {strategyCase.name}
                    </label>
                  )}
                  {renamingCase === strategyCase && (
                    <>
                      <CommonIconButton
                        activeTheme={props.activeTheme}
                        disabled={!validCaseName(renameCaseValue)}
                        icon={<FiCheck />}
                        style={{ fontSize: "smaller" }}
                        label="Save"
                        tooltipAbove
                        onClick={() => {
                          if (props.readonly) return;
                          renameCase(strategyCase.name, renameCaseValue);
                          if (selectedCase?.name === strategyCase.name) {
                            setSelectedCase({
                              ...strategyCase,
                              name: renameCaseValue,
                            });
                          }
                          setRenamingCase(undefined);
                          setRenameCaseValue("");
                        }}
                      />
                      <CommonIconButton
                        activeTheme={props.activeTheme}
                        label="Cancel"
                        tooltipAbove
                        style={{ fontSize: "smaller" }}
                        icon={<FiX />}
                        onClick={() => {
                          if (props.readonly) return;
                          setRenamingCase(undefined);
                          setRenameCaseValue("");
                        }}
                      />
                    </>
                  )}
                  <TierBadges collectedCriterias={collectedCriterias} />
                  <CurrencyLogoArray
                    currencies={currencies?.filter((currency) =>
                      strategyCase.included_currencies.includes(
                        currency.currency_name
                      )
                    )}
                    previewAmount={10}
                    size={"xs"}
                    expandable
                  />
                  {renamingCase !== strategyCase && (
                    <div className="case-features-container">
                      {props.children}
                      {props.entryCriteria && props.candleSize && (
                        <CommonButton
                          label={`Insert ${props.candleSize} Criteria`}
                          onClick={() => {
                            if (
                              !props.entryCriteria ||
                              !props.candleSize ||
                              !applicationIndicators
                            )
                              return;
                            const translatedCriteriaDeps =
                              getTranslatedCriteriaDependencies(
                                props.entryCriteria,
                                applicationIndicators
                              );
                            const hasAiDeps =
                              translatedCriteriaDeps.calculationProfiles.includes(
                                "AI"
                              );
                            if (hasAiDeps) {
                              const updatedCurrencies =
                                strategyCase.included_currencies.filter(
                                  (currency) =>
                                    applicationIndicators.ai_supported_currencies.includes(
                                      currency
                                    )
                                );
                              strategyCase.included_currencies =
                                updatedCurrencies;
                            }
                            setSelectedCase(strategyCase);
                            const updatedCase = { ...strategyCase };
                            updatedCase?.entry_criteria?.[
                              `candle_size_${props.candleSize}`
                            ].push(props.entryCriteria);
                            updateSelectedCase(updatedCase);
                            setCaseIndexInsertionMap((prev) => {
                              return {
                                ...prev,
                                [idx]: {
                                  entry: true,
                                  exit: prev[idx]?.exit,
                                },
                              };
                            });
                          }}
                          activeTheme={props.activeTheme}
                          disabled={caseIndexInsertionMap[idx]?.entry}
                          borderTheme="green-accent"
                          compact
                          leftIcon={<IoCodeWorking />}
                        />
                      )}
                      {props.exitCriteria && props.candleSize && (
                        <CommonButton
                          label={`Insert ${props.candleSize} Criteria`}
                          onClick={() => {
                            if (
                              !props.exitCriteria ||
                              !props.candleSize ||
                              !applicationIndicators
                            )
                              return;
                            const translatedCriteriaDeps =
                              getTranslatedCriteriaDependencies(
                                props.exitCriteria,
                                applicationIndicators
                              );
                            const hasAiDeps =
                              translatedCriteriaDeps.calculationProfiles.includes(
                                "AI"
                              );
                            if (hasAiDeps) {
                              const updatedCurrencies =
                                strategyCase.included_currencies.filter(
                                  (currency) =>
                                    applicationIndicators.ai_supported_currencies.includes(
                                      currency
                                    )
                                );
                              strategyCase.included_currencies =
                                updatedCurrencies;
                            }
                            setSelectedCase(strategyCase);
                            const updatedCase = { ...strategyCase };
                            updatedCase?.exit_criteria?.[
                              `candle_size_${props.candleSize}`
                            ].push(props.exitCriteria);
                            updateSelectedCase(updatedCase);
                            setCaseIndexInsertionMap((prev) => {
                              return {
                                ...prev,
                                [idx]: {
                                  entry: prev[idx]?.entry,
                                  exit: true,
                                },
                              };
                            });
                          }}
                          disabled={caseIndexInsertionMap[idx]?.exit}
                          activeTheme={props.activeTheme}
                          borderTheme="red-accent"
                          compact
                          leftIcon={<IoCodeWorking />}
                        />
                      )}
                      {props.openCase && (
                        <CommonButton
                          label="Open"
                          onClick={() => {
                            props.selectCase
                              ? props.selectCase(strategyCase)
                              : setSelectedCase(strategyCase);
                            props.openCase?.();
                          }}
                          activeTheme={props.activeTheme}
                          borderTheme={
                            props.readonly ? undefined : "green-accent"
                          }
                          compact
                          leftIcon={<PiCodeBlock />}
                        />
                      )}
                      {!props.readonly && (
                        <Menu
                          control={
                            <div className="common-icon-button-container">
                              <RiMoreLine />
                            </div>
                          }
                          withArrow
                          position="bottom"
                          placement="end"
                        >
                          <Menu.Item
                            onClick={() => {
                              copyCase(strategyCase);
                            }}
                            color="indigo"
                            icon={<FaCopy />}
                          >
                            Copy case
                          </Menu.Item>
                          <Menu.Item
                            color="red"
                            icon={<FaTrash />}
                            onClick={() => {
                              deleteCase(strategyCase.name);
                            }}
                          >
                            Delete case
                          </Menu.Item>
                        </Menu>
                      )}
                    </div>
                  )}
                </motion.div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export interface CriteriaInsertionMap {
  [idx: number]: {
    entry: boolean;
    exit: boolean;
  };
}
