import { TraderType } from "../pages/common/TradingDashboard";
import { BacktesterService } from "../services/BacktesterService";
import { LivetraderService } from "../services/LivetraderService";
import { LSTMForgeService } from "../services/LSTMForgeService";
import { PapertraderService } from "../services/PapertraderService";
import { StrategyManagerService } from "../services/StrategyManagerService";
import { UsermanagerService } from "../services/UserManagerService";

export const getBlueprintService = (traderType: TraderType) => {
  switch (traderType.traderType) {
    case "backtester":
      return BacktesterService.blueprint;
    case "livetrader":
      return LivetraderService.blueprint;
    case "papertrader":
      return PapertraderService.blueprint;
    case "usermanager":
      return UsermanagerService.blueprint;
    case "strategymanager":
      return StrategyManagerService.blueprint;
    case "lstmforge":
      return LSTMForgeService.blueprint;
    default:
      return LivetraderService.blueprint;
  }
};
